import React, { useState } from "react"
import "./OfficeSolutions.css"
import { IoIosArrowForward } from "react-icons/io"
import description from "../../../images/website-optimized-images/description.png"
import foto2 from "../../../images/website-optimized-images/visual directory2.png"
import foto3 from "../../../images/website-optimized-images/analytics.png"
import Fade from "react-reveal/Fade"

const OfficeSolutions = () => {
  const [showImage1, setShowImage1] = useState(true)
  const [showImage2, setShowImage2] = useState(false)
  const [showImage3, setShowImage3] = useState(false)
  const [showArrow1, setShowArrow1] = useState(true)
  const [showArrow2, setShowArrow2] = useState(false)
  const [showArrow3, setShowArrow3] = useState(false)

  const handleClickImage1 = () => {
    setShowImage1(true)
    setShowImage2(false)
    setShowImage3(false)
    setShowArrow1(true)
    setShowArrow2(false)
    setShowArrow3(false)
  }
  const handleClickImage2 = () => {
    setShowImage2(true)
    setShowImage1(false)
    setShowImage3(false)
    setShowArrow2(true)
    setShowArrow1(false)
    setShowArrow3(false)
  }
  const handleClickImage3 = () => {
    setShowImage3(true)
    setShowImage1(false)
    setShowImage2(false)
    setShowArrow3(true)
    setShowArrow2(false)
    setShowArrow1(false)
  }

  return (
    <>
      <div className="container" id="officeSolutionsDiv">
        <Fade bottom>
          <div className="textAreaOfficeSolution">
            <h5>Workplace management</h5>
            <h1>Solution description & main advantages</h1>
          </div>
        </Fade>
        <div className="container">
          <div className="row" id="colParentOfficeSolutions">
            <Fade bottom>
              <div className="col-lg-5" id="boxesParentOfficeSolutions">
                <div className="container">
                  <div
                    className="box1OfficeSolutions"
                    id="boxOneOfficeSolutions"
                    onClick={handleClickImage1}
                    style={{
                      boxShadow: showImage1
                        ? "0px 5px 30px rgba(183, 193, 203, 0.4)"
                        : "none",
                    }}
                  >
                    <h5> Booking resources</h5>
                    <ul style={{ listStyleType: "none" }} className="officeUl">
                      <li
                        style={{ listStyleType: "none" }}
                        className="officeSolutionsLi"
                      >
                        Booking desks and rooms (integrated with O365 <br /> and
                        Google Workspace)
                      </li>
                    </ul>
                    <Fade right when={showArrow1} distance="110px">
                      <div className="arrowOfficeSolutions1">
                        {" "}
                        <IoIosArrowForward
                          style={{ color: "#FFFFFF" }}
                          id="arrowIcon1"
                        />
                      </div>
                    </Fade>
                  </div>
                </div>

                <div className="container">
                  <div
                    className="box2OfficeSolutions"
                    onClick={handleClickImage2}
                    style={{
                      boxShadow: showImage2
                        ? "0px 5px 30px rgba(183, 193, 203, 0.4)"
                        : "none",
                    }}
                  >
                    <h5>Organisation specific info</h5>
                    <ul style={{ listStyleType: "none" }} className="officeUl">
                      <li className="officeSolutionsLi">Visual Directory</li>
                      <li className="officeSolutionsLi">
                        Teams and group views of calendars/bookings
                      </li>
                    </ul>
                    <Fade right when={showArrow2} distance="110px">
                      <div className="arrowOfficeSolutions2">
                        {" "}
                        <IoIosArrowForward style={{ color: "#FFFFFF" }} />
                      </div>
                    </Fade>
                  </div>
                </div>
                <div className="container-fluid">
                  <div
                    className="box3OfficeSolutions"
                    onClick={handleClickImage3}
                    style={{
                      boxShadow: showImage3
                        ? "0px 5px 30px rgba(183, 193, 203, 0.4)"
                        : "none",
                    }}
                  >
                    <h5>Advanced analytics</h5>
                    <ul style={{ listStyleType: "none" }} className="officeUl">
                      <li className="officeSolutionsLi">
                        Analytics info per floor, area, zone
                      </li>
                      <li className="officeSolutionsLi">
                        Analytics info per group/BU
                      </li>
                      <li className="officeSolutionsLi">
                        Detailed WiFi analysis
                      </li>
                      <li className="officeSolutionsLi">
                        Detailed resources usage analysis
                      </li>
                    </ul>
                    <Fade right when={showArrow3} distance="110px">
                      <div className="arrowOfficeSolutions3">
                        {" "}
                        <IoIosArrowForward style={{ color: "#FFFFFF" }} />
                      </div>
                    </Fade>
                  </div>
                </div>
              </div>
            </Fade>

            <div
              className="col-lg"
              id="image1Div"
              style={{
                display: showImage1 ? "block" : "none",
              }}
            >
              {" "}
              <Fade right when={showImage1}>
                <img src={description} alt="charts" className="image1Img" />
              </Fade>
            </div>

            <div
              className="col-sm"
              id="image2Div"
              style={{
                display: showImage2 ? "block" : "none",
              }}
            >
              <Fade right when={showImage2}>
                <img src={foto2} alt="charts" className="image2Img" />
              </Fade>
            </div>

            <div
              className="col-sm"
              id="image3Div"
              style={{
                display: showImage3 ? "block" : "none",
              }}
            >
              <Fade right when={showImage3}>
                <img src={foto3} alt="charts" className="image3Img" />
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OfficeSolutions
