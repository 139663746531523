import React from "react"
import "./OfficeHeroSection.css"
import { Button } from "react-bootstrap"
import mobile from "../../../images/website-optimized-images/mobile.png"
import Fade from "react-reveal/Fade"

const OfficeHeroSection = () => {
  return (
    <>
      <div className="container" id="officeHeroContainer">
        <Fade bottom>
          <div className="row" id="colContainerOfficeHero">
            <div className="col-lg" id="officeHeroTextArea">
              <h5>Office solution</h5>
              <h1>
                U-find solution for your office <br /> workspace
              </h1>
              <p>
                Welcome to u-find App, your partner to help you locate the right
                person, <br />
                equipment, meeting room or desk for a more productive work. Get
                the <br />
                location insights you need when you need it most!
              </p>
              {/* <Button className="officeHeroButton" variant="primary">
              View demo
            </Button> */}
            </div>
            <div className="col-lg" id="officeHeroImageDiv">
              <img
                src={mobile}
                className="officeHeroImage"
                alt="ufind-officeHeroImage"
              />
            </div>
          </div>
        </Fade>
      </div>
    </>
  )
}

export default OfficeHeroSection
