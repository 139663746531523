import React, { useState } from "react"
import "./RequestDemo.css"
import { Form, Button, FloatingLabel, Modal } from "react-bootstrap"
import Fade from "react-reveal/Fade"
import DemoModal from "../DemoModal/DemoModal"

const RequestDemo = () => {
  // const [show, setShow] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [company, setCompany] = useState("")
  const [message, setMessage] = useState("")
  const [sent, setSent] = useState("")

  const handleFirstName = e => {
    setFirstName(e.target.value)
  }
  const handleLastName = e => {
    setLastName(e.target.value)
  }
  const handleEmail = e => {
    setEmail(e.target.value)
  }
  const handleCompany = e => {
    setCompany(e.target.value)
  }
  const handleMessage = e => {
    setMessage(e.target.value)
  }
  const clearForm = () => {
    setFirstName("")
    setLastName("")
    setEmail("")
    setCompany("")
    setMessage("")
    // setSent("")
  }

  const submitHandler = e => {
    e.preventDefault()
    const userInputFormData = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      company: company,
      message: message,
    }

    console.log(userInputFormData, "data")
    setSent("Sending message...")
    sendEmail()
    clearForm()
  }

  // const handleClose = () => setShow(false)
  // const handleShow = () => setShow(true)

  const sendEmail = () => {
    var myHeaders = new Headers()
    myHeaders.append("token", "MXSDE7DZbpZrMmSbbUZQ1WRQMePamniv")
    myHeaders.append("Content-Type", "application/json")

    var raw = JSON.stringify({
      subject: "Welcome",
      html: `<h2>Informations</h2>
      <ul>
      <li>First name: ${firstName}</li>
      <li>Last name: ${lastName}</li>
      <li>E-mail: ${email}</li>
      <li>Company: ${company}</li>
      <li>Message: <h5>${message}</h5></li>
      </ul>
      `,
    })

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    }

    fetch(
      "https://europe-west2-ufind-prod.cloudfunctions.net/sendEmail?=",

      requestOptions
    )
      .then(response => response.text())
      .then(result => {
        console.log(result)
        setSent("Thank you for your contact, your message has been sent.")
      })
      .catch(error => {
        console.log("error", error)
        setSent("Error sending message, please try again.")
      })
  }

  return (
    <>
      <div className="container-fluid" id="requestDemoContainer">
        <Fade bottom>
          <div className="row">
            <div className="col-lg-5" id="requestDemoTextArea">
              <h5>Free 15 day trial</h5>
              <h1>Try our solution now</h1>
              <p>
                We are ready to discuss your specific requirements and what{" "}
                <br />
                would work for your company. <br />
                If you have a Cisco Meraki solution, you can try u-find for 15
                days.
              </p>

              <Button
                variant="primary"
                className="tryItButton"
                // href="https://docs.google.com/forms/d/e/1FAIpQLSc2iCNp01Z-757ljDEnMzFO-FaLpMhBrnYbOGa6SKaF83QTyQ/viewform"
                // target="_blank"
                // rel="noopener noreferrer"
                // onClick={handleShow}
                type="button"
                // className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Try U-find
              </Button>
              <DemoModal />
              {/* <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton className="modalHeaderH1">
                  <Modal.Title className="modalTitle">
                    Are you a Cisco Meraki customer?
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="modalBodyText">
                  <div className="container" id="modalBodyTitleText">
                    <h5>
                      Please confirm that you have access to your Meraki admin
                      dashboard to generate a Meraki API key.
                    </h5>
                  </div>
                  <div className="container" id="modalPContainer">
                    <h6>Disclaimer :</h6>
                    <p>
                      U-find solution will have need to have access to your
                      Meraki Dashboard with read-only permissions. No write
                      actions will be made, so please make sure to generate API
                      with a user that has Read-Only permissions.
                    </p>
                    <p>
                      Besides access to Meraki Dashboard API, U-find requests
                      you to enable ScanAPI to send device location information
                      every minute. To setup U-find platform and trial you need
                      to have Meraki dashboard administrator rights in order to
                      enable ScanAPI.
                    </p>
                    <p>
                      After the U-find setup, all the information about users,
                      devices and device location will be shared with U-find for
                      the trial period. No correlation between users and devices
                      is kept, only used for Real Time information if you wish
                      to enable it, keeping GDPR rules of anonymisation and
                      end-user protection.
                    </p>
                  </div>
                </Modal.Body>
                <Modal.Footer className="modalFooter">
                  <Button
                    variant="secondary"
                    onClick={handleClose}
                    className="modalBtnNo"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleClose}
                    className="modalBtnYes"
                    href="https://app.u-find.me/trial"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Confirm
                  </Button>
                </Modal.Footer>
              </Modal> */}
            </div>
            <div className="col-lg" id="colRequestDemo">
              <div className="requestDemoFormDiv">
                <h4>Contact us</h4>
                <Form className="form" onSubmit={submitHandler}>
                  <div className="row">
                    <div className="col-lg" id="firstNameRow">
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicFirstName"
                      >
                        <Form.Label className="formLabel">
                          First name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="First name"
                          value={firstName}
                          onChange={handleFirstName}
                          required
                        />
                      </Form.Group>
                    </div>
                    <div className="col-lg" id="lastNameRow">
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicLastName"
                      >
                        <Form.Label className="formLabel">Last name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Last name"
                          value={lastName}
                          onChange={handleLastName}
                          required
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="formLabel">E-mail</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="E-mail"
                      className="formInputs"
                      value={email}
                      onChange={handleEmail}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicCompanyRole">
                    <Form.Label className="formLabel">Company</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Company"
                      className="formInputs"
                      value={company}
                      onChange={handleCompany}
                      required
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="formBasicNumberOfEmployees"
                  >
                    <Form.Label className="formLabel">Message</Form.Label>
                    <FloatingLabel
                      controlId="floatingTextarea2"
                      label="Message"
                    >
                      <Form.Control
                        as="textarea"
                        placeholder="Message"
                        style={{ height: "100px" }}
                        value={message}
                        onChange={handleMessage}
                        required
                      />
                    </FloatingLabel>
                  </Form.Group>
                  <p className="msgAppear">{sent}</p>
                  <div className="btnDiv">
                    <Button
                      variant="primary"
                      type="submit"
                      className="requestButton"
                      // href="https://docs.google.com/forms/d/e/1FAIpQLSc2iCNp01Z-757ljDEnMzFO-FaLpMhBrnYbOGa6SKaF83QTyQ/viewform"
                      // target="_blank"
                      // rel="noopener noreferrer"
                    >
                      Send
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </>
  )
}

export default RequestDemo
