import React from "react"
import "./SmartOffice.css"
import mobile2 from "../../../images/website-optimized-images/ufind-mobile-mockup-preview.png"
import Fade from "react-reveal/Fade"

const SmartOffice = () => {
  return (
    <>
      <div className="smartOfficeContainer">
        <Fade bottom>
          <h5>Offices & People</h5>
          <h1>Smart office solution</h1>
          <p>
            New generation workforce have different needs and seeks different
            working experiences. <br />
            People, spaces and tools are blended to create a living entity that
            adapt, evolves and <br />
            create a unique environment that shapes the Company Culture.
          </p>
          <div className="container" id="smartOffice-container">
            <div className="row">
              <div
                className="col-lg order-2 order-lg-1"
                id="smartOfficeImageDiv"
              >
                <img
                  src={mobile2}
                  className="smartOfficeImage"
                  alt="ufind-smartOfficeImage"
                />
              </div>
              <div
                className="col-lg order-1 order-lg-2"
                id="smartOfficeTextArea"
              >
                <div className="smartOfficeList1">
                  <h5>People</h5>
                  <ul>
                    <li className="smartOfficeLi">
                      Resource finding and reservation (desk, car park,
                      restaurant, etc.)
                    </li>
                    <li className="smartOfficeLi">
                      Recommendations based on history and profile
                    </li>
                    <li className="smartOfficeLi">
                      Integration with existing office tools (calendar,
                      directory, collaboration)
                    </li>
                    <li className="smartOfficeLi">
                      People/team members finding and planning activities
                    </li>
                    <li className="smartOfficeLi">
                      Global teams behaviours in the office
                    </li>
                    <li className="smartOfficeLi">
                      Alerts of space usage or environment information (safety
                      and security)
                    </li>
                    <li className="smartOfficeLi">
                      Location based Communication (office, floor or area)
                    </li>
                  </ul>
                  <div className="smartOfficeList2">
                    <h5>Workspace</h5>
                    <ul>
                      <li className="smartOfficeLi">
                        Monitoring people in office space
                      </li>
                      <li className="smartOfficeLi">
                        Visibility and reporting of resource usage (HR,
                        facilities, etc.)
                      </li>
                      <li className="smartOfficeLi">
                        Reporting and analysis (based on seasons, weekly
                        dispersion or time of day)
                      </li>
                      <li className="smartOfficeLi">
                        Office “environment” analysis (temperature, humidity,
                        CO2)
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </>
  )
}

export default SmartOffice
