import * as React from "react"
import { useEffect } from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import OfficeHeroSection from "../components/Office/OfficeHeroSection/OfficeHeroSection"
import SmartOffice from "../components/Office/SmartOffice/SmartOffice"
import Optimization from "../components/Office/Optimization/Optimization"
import OfficeSolutions from "../components/Office/OfficeSolutions/OfficeSolutions"
import Pricing from "../components/Office/Pricing/Pricing"
import RequestDemo from "../components/RequestDemo/RequestDemo"
import Scroll from "../components/ScrollToTop/Scroll"

const Office = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Layout>
        <Scroll showBelow={250} />
        <Seo title="Office" />
        <OfficeHeroSection />
        <SmartOffice />
        <Optimization />
        <OfficeSolutions />
        {/* <Pricing /> */}
        <RequestDemo />
        {/* <Link to="/">Go back to the homepage</Link> */}
      </Layout>
    </>
  )
}

export default Office
