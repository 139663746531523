import React from "react"
import "./Optimization.css"
import optimizationImage from "../../../images/website-optimized-images/optimization.png"
import { IoMdCheckmarkCircle } from "react-icons/io/"
import Fade from "react-reveal/Fade"

const Optimization = () => {
  return (
    <>
      <div className="container" id="optimization-container">
        <Fade bottom>
          <div className="row" id="rowOptimizations">
            <div
              className="col-lg order-2 order-lg-1"
              id="optimizationImageDiv"
            >
              <img
                src={optimizationImage}
                className="optimizationImage"
                alt="ufind-optimizationImage"
              />
            </div>
            <div
              className="col-lg order-1 order-lg-2"
              id="optimizationTextArea"
            >
              <h5>Office spaces</h5>
              <h1>Optimization</h1>
              <p>
                New generation shopping spaces require a better and closer
                customers engagement.
                <br /> Transform visitors engagement and drive your business
                results. <br /> Build on top of existing investments (WiFi) and
                increase with existing or new sensors. <br /> <br /> Our retail
                solution offers:
              </p>

              <div className="col-lg-5" id="rowOptimization">
                <p>
                  <IoMdCheckmarkCircle className="checkIconOptimization" />
                  Space usage visibility
                </p>
                <p>
                  <IoMdCheckmarkCircle className="checkIconOptimization" />
                  Resource utilization analysis
                </p>
                <p>
                  <IoMdCheckmarkCircle className="checkIconOptimization" />
                  Integration with existing building systems for energy
                  efficiency
                </p>
                <p>
                  <IoMdCheckmarkCircle className="checkIconOptimization" />
                  Space and resources adjustments
                </p>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </>
  )
}

export default Optimization
